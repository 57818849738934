export const selectedRoles = ['ADMIN'];

export const specifiedRoles = ['NATIONAL HEAD', 'NH'];

export const modulesForSpecifiedRoles = [
  { name: 'Employees' },
  { name: 'Customers' },
  { name: 'Farmers' },
  { name: 'Meeting' },
  { name: 'Inventory' },
];

export const modulesForOtherRoles = [
  { name: 'Employees' },
  { name: 'Customers' },
  { name: 'Farmers' },
  { name: 'Meeting' },
];

export const restrictedRoles = ['GM', 'ZM', 'TM ASM', 'SE', 'SO'];
